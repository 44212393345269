exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-absolute-zero-tsx": () => import("./../../../src/pages/absolute-zero.tsx" /* webpackChunkName: "component---src-pages-absolute-zero-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-canon-tsx": () => import("./../../../src/pages/canon.tsx" /* webpackChunkName: "component---src-pages-canon-tsx" */),
  "component---src-pages-credits-tsx": () => import("./../../../src/pages/credits.tsx" /* webpackChunkName: "component---src-pages-credits-tsx" */),
  "component---src-pages-daily-photograph-tsx": () => import("./../../../src/pages/daily-photograph.tsx" /* webpackChunkName: "component---src-pages-daily-photograph-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reading-tsx": () => import("./../../../src/pages/reading.tsx" /* webpackChunkName: "component---src-pages-reading-tsx" */),
  "component---src-pages-septenary-tsx": () => import("./../../../src/pages/septenary.tsx" /* webpackChunkName: "component---src-pages-septenary-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-duel-the-heart-vs-the-sun-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/a-duel-the-heart-vs-the-sun/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-a-duel-the-heart-vs-the-sun-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-about-to-take-my-last-breath-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/about-to-take-my-last-breath/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-about-to-take-my-last-breath-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-another-chance-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/another-chance/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-another-chance-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-droom-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/droom/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-droom-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-feeling-claustrophobic-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/feeling-claustrophobic/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-feeling-claustrophobic-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-feeling-fatherly-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/feeling-fatherly/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-feeling-fatherly-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-fire-and-cold-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/fire-and-cold/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-fire-and-cold-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-freedom-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/freedom/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-freedom-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-in-september-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/in-september/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-in-september-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-introspection-gone-wrong-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/introspection-gone-wrong/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-introspection-gone-wrong-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-jealousy-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/jealousy/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-jealousy-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-lost-in-meaning-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/lost-in-meaning/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-lost-in-meaning-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-maha-bharat-mein-bhrashtachar-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/maha-bharat-mein-bhrashtachar/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-maha-bharat-mein-bhrashtachar-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-mumbai-romance-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/mumbai-romance/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-mumbai-romance-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-on-a-rainy-day-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/on-a-rainy-day/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-on-a-rainy-day-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-on-a-sleepless-night-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/on-a-sleepless-night/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-on-a-sleepless-night-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-on-a-slow-morning-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/on-a-slow-morning/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-on-a-slow-morning-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-rusty-old-paper-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/rusty-old-paper/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-rusty-old-paper-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-santulan-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/santulan/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-santulan-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-staring-out-of-the-window-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/staring-out-of-the-window/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-staring-out-of-the-window-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-stranger-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/stranger/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-stranger-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-thinking-about-answer-to-everything-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/thinking-about-answer-to-everything/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-thinking-about-answer-to-everything-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-thinking-about-spider-man-index-mdx": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/thinking-about-spider-man/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-thinking-about-spider-man-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-travel-reflections-1-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/travel-reflections-1/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-travel-reflections-1-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-content-blog-what-do-you-desire-index-md": () => import("./../../../src/templates/blog-post.tsx?__contentFilePath=/opt/build/repo/content/blog/what-do-you-desire/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-content-blog-what-do-you-desire-index-md" */),
  "component---src-templates-septenary-day-tsx": () => import("./../../../src/templates/septenary-day.tsx" /* webpackChunkName: "component---src-templates-septenary-day-tsx" */),
  "component---src-templates-tag-posts-tsx": () => import("./../../../src/templates/tag-posts.tsx" /* webpackChunkName: "component---src-templates-tag-posts-tsx" */)
}

